import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import HeroDigitalMarketing from "../../components/ServiceSinglePage/Hero/DigitalMarketing/HeroDigitalMarketing"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import ServiceCTA from "../../components/ServiceLanding/CTA/ServiceCTA"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"
import localisationData from '../../data/localisation.json'
import ThreeColumnContent from "../../components/ThreeColumnContent"
function DigitalPage({ location }) {
    const heroTextTitle = "Digital Marketing"
    const heroTextSmallText =
        "Saigon Digital is a full-service digital agency, with a special interest in delivering fast, flexible and user centric digital solutions."
    const ctaTitle =
        "Contact us today and learn more about how we can help you grow your business."
    const heroData = [
        "Saigon Digital, your full-service digital agency for integrated digital marketing solutions!",
        "We are Saigon Digital, a team of experts who take a personalised approach to each service we offer, ensuring we understand your unique business needs and goals. Whether you're looking to increase website traffic, generate leads, establish a strong brand identity, or create engaging content, we have the expertise to help you succeed.",
        "Saigon Digital offers a comprehensive Business Growth Accelerator service that combines various services such as SEO, paid advertising, social media management, email marketing, and content creation. Our goal is to help businesses generate leads, boost website traffic, and increase revenue growth. Our services are tailored to meet specific business objectives, whether it is expanding into new markets, launching new products, or enhancing customer retention."
    ]
    const iconBlockTitle = "Our Integrated Digital Marketing Solutions "
    const icons = [
        {
            name: "checklist",
            title: "Planning",
            text: "We develop a customized digital marketing plan that aligns with your overall business objectives, targets your ideal audience, and ensures your digital presence is consistent and successful online."
        },
        {
            name: "search",
            title: "SEO",
            text: "We optimize your website for search engine results pages (SERPs) and prepare optimized content to ensure that you rank higher for the keywords that matter most to your business."
        },
        {
            name: "dart",
            title: "Paid Ads",
            text: "Our team of experts will help you create and manage targeted campaigns on Facebook, Google, and Tiktok, ensuring that you get the most out of your advertising budget."
        },
        {
            name: "setting",
            title: "Social Media Management",
            text: "Our Social Media Management service is focused on building a strong social media presence for your business. We create and curate engaging content that resonates with your audience, drives engagement, and helps your brand stand out online."
        },
        {
            name: "mail",
            title: "Email Marketing",
            text: "We help you build relationships with your audience through targeted email campaigns that keep your audience engaged."
        },
        {
            name: "signature",
            title: "Content Writing",
            text: "Our high-quality, engaging content resonates with your target audience and aligns with your business goals and voice. We specialize in blog posts, social media updates, and email newsletters."
        },
        {
            name: "tag",
            title: "Branding Services",
            text: "We develop a unique brand identity that reflects your company's values, mission, and personality. Our services include logo design, brand messaging, color palette, and more, all customized to your brand and target audience."
        },
        {
            name: "bubble",
            title: "2D Design",
            text: "Our stunning visuals grab your audience's attention and are effective in social media, website design, and other marketing materials. We specialize in graphics customized to your brand and target audience."
        }
    ]
    const context = {
        pageName: "Service | Digital Marketing",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroDigitalMarketing
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <ServiceIconBlock title={iconBlockTitle} icons={icons} />
            <div className="mt-36">
                <ServiceCTA title={ctaTitle} />
            </div>
            <ThreeColumnContent localisationData={localisationData.digitalmarketing}/>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.digitalMarketingFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default DigitalPage
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why should I choose Saigon Digital's Digital Marketing services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Saigon Digital stays ahead of the curve by continuously learning and adapting to the latest trends and technologies. We ensure that our digital marketing strategy is always cutting-edge, providing you with the best possible results. Choosing us means partnering with a team that is dedicated to your success."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is Digital Marketing?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Digital marketing is the practice of promoting products or services using digital channels such as search engines, social media, email, and websites. A well-executed digital marketing strategy can help businesses reach a wider audience and drive measurable results."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What services do you offer as a Digital Marketing agency?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Our digital marketing company offers a range of digital marketing services, including SEO, PPC, content marketing, social media management, email marketing, and more. Each service is designed to enhance your online presence and drive business growth."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What makes your Digital Marketing company different from others?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Our digital marketing company stands out due to our extensive experience, data-driven approach, and commitment to delivering results. We tailor our digital marketing services to meet the unique needs of each client, ensuring maximum impact."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How do you integrate different Digital Marketing services for maximum impact?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "We integrate various digital marketing services, such as SEO, PPC, and social media marketing, to create a cohesive strategy. This holistic approach ensures that all channels work together to amplify your message and accelerate your business growth."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Do you offer customised pricing or packages for Digital Marketing services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Yes, our digital marketing agency offers customised pricing and packages tailored to your business needs. Whether you need a comprehensive digital marketing strategy or specific services, we provide options that fit your budget."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What kind of support can I expect during a Digital Marketing campaign?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "During a digital marketing campaign, you can expect ongoing support, regular updates, and strategic adjustments from our team. Our digital marketing agency is committed to ensuring your campaign’s success through continuous optimisation and communication"
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>
        <SEO
            title="Digital Marketing | Saigon Digital"
            description="Saigon Digital is a full-service digital agency, with a special interest in delivering fast, flexible and user centric digital solutions."
        />
    </>
)
