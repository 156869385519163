import React, { useEffect, useRef } from "react"

import Vector from '@images/serviceHero/digital-marketing/Vector.svg'
import Vector1 from '@images/serviceHero/digital-marketing/Vector-1.svg'
import Vector2 from '@images/serviceHero/digital-marketing/Vector-2.svg'
import Vector3 from '@images/serviceHero/digital-marketing/Vector-3.svg'
import Vector4 from '@images/serviceHero/digital-marketing/Vector-4.svg'
import Vector5 from '@images/serviceHero/digital-marketing/Vector-5.svg'
import Vector6 from '@images/serviceHero/digital-marketing/Vector-6.svg'
import Vector7 from '@images/serviceHero/digital-marketing/Vector-7.svg'
import Vector8 from '@images/serviceHero/digital-marketing/Vector-8.svg'
import gsap from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const DigitalMarketingIcon = props => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)

    useEffect(() => {
        const element = ref.current
        gsap.fromTo(
            element.querySelector('.piece-1'),
            {
                top: 0,
                left: 'calc(7% + 5px)',
                ease: 'ease.out'
            },
            {
                top: 'calc(85% + 62px)',
                left: 'calc(50% + 40px)',

                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-2'),
            {
                top: '50px',
                left: '150px',
                ease: 'ease.out'
            },
            {
                top: 'calc(85% - 2px)',
                left: 'calc(50%  + 89px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-3'),
            {
                left: '300px',
                top: '0',
            },
            {
                left: 'calc(50% + 195px)',
                top: 'calc(85% + 50px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
        gsap.fromTo(
            element.querySelector('.piece-4'),
            {
                left: '450px',
                top: '200px',
                ease: 'ease.out'
            },
            {
                left: 'calc(50% + 160px)',
                top: 'calc(85% - 15px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
        gsap.fromTo(
            element.querySelector('.piece-5'),
            {
                top: '250px',
                left: '80px',
                ease: 'ease.out'
            },
            {
                top: 'calc(85% + 120px)',
                left: 'calc(50% + 65px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
        gsap.fromTo(
            element.querySelector(".piece-6"),
            {
                left: '350px',
                top: '200px',
                ease: 'ease.out'
            },
            {
                left: 'calc(50% + 210px)',
                top: 'calc(85% + 25px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
        gsap.fromTo(
            element.querySelector(".piece-7"),
            {
                left: '350px',
                top: '350px',
                ease: 'ease.out'
            },
            {
                left: 'calc(50% + 217px)',
                top: 'calc(85% + 50px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
        gsap.fromTo(
            element.querySelector(".piece-8"),
            {
                top: '500px',
                left: '300px',
                ease: 'ease.out',
            },
            {
                top: 'calc(85% - 35px)',
                left: 'calc(50% + 15px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
        gsap.fromTo(
            element.querySelector(".piece-9"),
            {
                top: 'calc(6% - 3px)',
                left: 'calc(36% + 10px)',
                ease: 'ease.out'
            },
            {
                top: 'calc(85% + 75px)',
                left: 'calc(50% + 220px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
    }, [])

    return <div ref={ref} className="hidden xl:block relative md:col-[1_/_2] md:row-[2_/_4]">
        <div className="absolute top-[10%] wrap-pice"></div>
        <div className="piece-1 absolute z-[2] w-[calc(4231px*0.06)]">
            <img alt="digital-marketing-piece" src={Vector} />
        </div>
        <div className="piece-2 absolute z-[2] w-[calc(4231px*0.06)]">
            <img alt="digital-marketing-piece" src={Vector1} />
        </div>
        <div className="piece-3 absolute z-[2] w-[calc(4231px*0.06)]">
            <img alt="digital-marketing-piece" src={Vector2} />
        </div>
        <div className="piece-4 absolute z-[2] w-[calc(4231px*0.06)]">
            <img alt="digital-marketing-piece" src={Vector3} />
        </div>
        <div className="piece-5 absolute z-[2] w-[calc(4231px*0.06)]">
            <img alt="digital-marketing-piece" src={Vector4} />
        </div>
        <div className="piece-6 absolute z-[2] w-[calc(4231px*0.06)]">
            <img alt="digital-marketing-piece" src={Vector5} />
        </div>
        <div className="piece-7 absolute z-[2] w-[calc(4231px*0.06)]">
            <img alt="digital-marketing-piece" src={Vector6} />
        </div>
        <div className="piece-8 absolute z-[1] w-[calc(4231px*0.06)]">
            <img alt="digital-marketing-piece" src={Vector7} />
        </div>
        <div className="piece-9 absolute z-[2] w-[calc(4231px*0.06)]">
            <img alt="digital-marketing-piece" src={Vector8} />
        </div>
    </div>
}

export default DigitalMarketingIcon;