import React from 'react'
import Hero from '../Hero'
import DigitalMarketingIcon from './DigitalMarketingIcon'

const HeroDigitalMarketing = ({
    heroTextTitle,
    heroTextSmallText,
    ctaTitle,
    heroData
}) => {
    return (
        <Hero
            heroTextTitle={heroTextTitle}
            heroTextSmallText={heroTextSmallText}
            ctaTitle={ctaTitle}
            heroData={heroData}
        >
            <DigitalMarketingIcon />
        </Hero>
    )
}

export default HeroDigitalMarketing
